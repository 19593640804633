export function slider() {
  var sliderArray = [
    './assets/img/mv_text01.png',
    './assets/img/mv_text02.png',
    './assets/img/mv_text03.png',
    './assets/img/mv_text04.png',
  ];

  var $slider = $('.slider');
  $slider.slick({
    autoplay: false,
    dots: false,
    fade: true,
    prevArrow:
      '<div class="swiper-button-prev"><img src="./assets/img/arrow_prev.png" class="slide-arrow prev-arrow"></div>',
    nextArrow:
      '<div class="swiper-button-next"><img src="./assets/img/arrow_next.png" class="slide-arrow next-arrow"></div>',
  });

  $slider.on('afterChange', function(slick, currentSlide) {
    var currentSlide = $slider.slick('slickCurrentSlide');

    $('.js-mvPager').removeClass('is-active');
    setTimeout(function() {
      $('.js-mvPager').addClass('is-active');
      $('.js-mvPager')
        .find('img')
        .remove();
      $('.js-mvPager').append(
        '<img src="' + sliderArray[currentSlide] + '" alt="LADIES">'
      );
    }, 10);
  });
}

export function smoothScroll() {
  $('a[href^="#"]').click(function() {
    // スクロールの速度
    var speed = 400; // ミリ秒で記述
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top;
    $('body,html').animate({ scrollTop: position }, speed, 'swing');

    if ($(window).width() < 769) {
      if ($('.js-hamburger').hasClass('is-active')) {
        $('.js-hamburger').removeClass('is-active');
        $('.js-nav').removeClass('is-active');

        setTimeout(function() {
          $('.js-navList').removeClass('is-active');
        });
      }
    }

    return false;
  });
}

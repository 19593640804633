import { scroll } from './modules/scroll';
import { slider } from './modules/slider';
import { header } from './modules/header';
import { smoothScroll } from './modules/smoothScroll';
import { validate } from './modules/validate';

$(window).on('load', function() {
  slider();
  header();
  smoothScroll();
  validate();
});

var resizeTimer = null;
$(window).on('load resize', function() {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {
    scroll();
  }, 100);
});

$(window).on('load', function() {
  setTimeout(function() {
    $('body').removeClass('is-hide');
  }, 300);
});

var scrollTimer = null;
$(window).on('load scroll resize', function() {
  clearTimeout(scrollTimer);

  // let scrollWidth = $(window).width();
  // let scrollTop = $(window).scrollTop();
  // let mvHeight = $('#top').outerHeight();
  // let urlHash = location.hash;

  // scrollTimer = setTimeout(function() {
  //   if (scrollWidth > 768) {
  //     if (urlHash != '#TOP') {
  //       $('.header').addClass('is-scroll');
  //     } else {
  //       $('.header').removeClass('is-scroll');
  //     }
  //   } else {
  //     if (scrollTop > mvHeight) {
  //       $('.header').addClass('is-scroll');
  //     } else {
  //       $('.header').removeClass('is-scroll');
  //     }
  //   }
  // }, 50);

  // if ($(window).width() < 769) {
  //   $('.js-anchor').on('click', function() {
  //     let href = $(this).attr('href');
  //     var position = $(href).offset().top - $('.header_cont').outerHeight() + 5;
  //     $('html, body').animate(
  //       {
  //         scrollTop: position,
  //       },
  //       600,
  //       'swing'
  //     );
  //     return false;
  //   });
  // }
});

export function validate() {
  //標準エラーメッセージの変更
  $.extend($.validator.messages, {
    email: '正しいメールアドレスの形式で入力して下さい',
    required: '入力必須です',

    phone: '正しい電話番号の形式で入力してください',
  }); //追加ルールの定義

  var methods = {
    phone: function(value, element) {
      return (
        this.optional(element) || /^\d{11}$|^\d{3}-\d{4}-\d{4}$/.test(value)
      );
    },
  };

  //メソッドの追加
  $.each(methods, function(key) {
    $.validator.addMethod(key, this);
  });

  //入力項目の検証ルール定義
  var rules = {
    company: { required: true },
    name: { required: true },
    phone: 'phone',
    email: { required: true, email: true },
    main: { required: true },
    agree: { required: true },
  };

  //入力項目ごとのエラーメッセージ定義
  var messages = {
    company: {
      required: '貴社名を入力してください',
    },
    name: {
      required: '名前を入力してください',
    },
    phone: {
      required: '電話番号をを入力してください',
    },
    email: {
      required: 'メールアドレスをを入力してください',
    },
    main: {
      required: 'お問い合わせ内容をを入力してください',
    },
    agree: {
      required: '個人情報保護方針に同意してください',
    },
  };

  $(function() {
    $('#form').validate({
      rules: rules,
      messages: messages,

      //エラーメッセージ出力箇所調整
      errorPlacement: function(error, element) {
        if (element.is(':radio')) {
          error.appendTo(element.parent());
        } else {
          error.insertAfter(element);
        }
      },
    });
  });
}

const $hamburger = $('.js-hamburger');
const $nav = $('.js-nav');
const $navList = $('.js-navList');

export function header() {
  hamburger();
  // close();
}

function hamburger() {
  $hamburger.on('click', function() {
    $(this).toggleClass('is-active');
    $nav.toggleClass('is-active');

    $navList.each(function(e) {
      let $this = $(this);
      setTimeout(function() {
        $this.toggleClass('is-active');
      }, e * 100);
    });
  });
}

function close() {
  $navList.on('click', function() {
    $hamburger.removeClass('is-active');
    $nav.removeClass('is-active');

    // if ($(window).width() < 769) {
    //   $('.js-anchor').on('click', function() {
    //     let href = $(this).attr('href');
    //     var speed = 500;
    //     var position = $(href).offset().top;
    //     $('html, body').animate({ scrollTop: position }, speed, 'swing');
    //     return false;
    //   });
    // }

    // setTimeout(function() {
    //   $navList.removeClass('is-active');
    // }, 300);
  });
}

export function scroll() {
  const $section = $('.js-section'); // 各スライド
  const $pager = $('#js-pager'); // ページャー枠

  // scrollifyのオプション設定
  let option = {
    section: '.js-section',
    easing: 'swing',
    scrollSpeed: 600,
    scrollbars: true,
    before: function(index, section) {
      setCurrent(index); // 現在のsectionにクラスを設定
      pagerCurrent(index); // ページャーに対応する順番にクラス名を付与
    },
    afterRender: function() {
      createPager(); // ページャーの作成
      setCurrent(); // 現在のsectionにクラスを設定
    },
  };

  $(function() {
    if ($(window).width() > 768) {
      $('.pager li').remove();
      $.scrollify(option); // scrollifyの実行
    } else {
      $.scrollify.destroy();
    }
  });

  // 現在のsectionにクラスを設定
  function setCurrent(index = 0) {
    // 一旦、すべてのsectionのクラスをとる
    $section.removeClass('is-show');
    // 現在のsectionのみにクラスを付与
    $section.eq(index).addClass('is-show');
  }

  // ページャーに対応する順番にクラス名を付与
  function pagerCurrent(index = 0) {
    let $li = $pager.find('li');
    $li.removeClass('is-current');
    $li.eq(index).addClass('is-current');
  }

  // ページャーの作成
  function createPager() {
    $section.each(function(i, e) {
      let pagerNumber;
      // ページ内リンク先の作成
      let sectionName = '#' + $(e).attr('data-section-name');

      // 最初のliにはクラスを付与
      let addClass = '';

      // URLのアンカー（#以降の部分）を取得
      let urlHash = location.hash;

      // URLにアンカーが存在する場合
      if (urlHash) {
        // アンカーが#osakaかどうかを判断する
        if (urlHash == sectionName) {
          addClass = 'is-current';
        }
      }

      // liのHTML作成
      let html = '';
      i += 1;
      pagerNumber = '0' + i;

      html += '<li class="' + addClass + '">';
      html +=
        '<a href="' +
        sectionName +
        '" data-text="' +
        $(e).attr('data-text') +
        '"><span class="pager_num">' +
        pagerNumber +
        '</span><span class="pager_active">-</span></a>';
      html += '</li>';
      $pager.append(html);
    });

    pagerLink();
  }

  // ページャーでaタグをクリックされたらスクロールする
  function pagerLink() {
    if ($(window).width() > 768) {
      $('a').on('click', function() {
        $.scrollify.move($(this).attr('href'));
      });
    }
  }
}
